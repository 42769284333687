import {
  ConversationDetails,
  ConversationUpdateEvent,
  Message,
  PendingMessage,
} from '@conversations/conversation/state/conversation/conversation-state.model';
import { StateOperator } from '@ngxs/store';

export class LoadConversation {
  static readonly type = '[Conversation] Load conversation';

  constructor(public conversationId: string) {}
}

export class LoadNextMessages {
  static readonly type = '[Conversation] Load next messages';
}

export class LoadPreviousMessages {
  static readonly type = '[Conversation] Load previous messages';
}

export class LoadMessagesAfterDate {
  static readonly type = '[Conversation] Load messages after date';

  constructor(public date: string) {}
}

export class PatchConversationDetails {
  static readonly type = '[Conversation] Patch conversation details';

  constructor(
    public conversationId: string,
    public patch: StateOperator<ConversationDetails>,
  ) {}
}

export class ReloadConversationDetails {
  static readonly type = '[Conversation] Reload conversation details';

  constructor(public conversationId: string) {}
}

export class AddNewMessage {
  static readonly type = '[Conversation] Add new message';

  constructor(
    public conversationId: string,
    public messageId: string,
    public traceId: string | undefined,
  ) {}
}

export class AddPendingMessage {
  static readonly type = '[Conversation] Add pending message';

  constructor(
    public conversationId: string,
    public pendingMessage: PendingMessage,
  ) {}
}

export class PatchConversationMessage {
  static readonly type = '[Conversation] Patch conversation message';

  constructor(
    public conversationId: string,
    public messageId: string,
    public patch: StateOperator<Message>,
  ) {}
}

export class HandleConversationUpdate {
  static readonly type = '[Conversation] Handle conversation update';

  constructor(public event: ConversationUpdateEvent) {}
}

export class ResetConversationState {
  static readonly type = '[Conversation] Reset conversation state';
}
