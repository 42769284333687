import { Component, Input, type OnInit, inject, output } from '@angular/core';
import { type Company, type CompanySearchModel } from '@core/models/company';
import { HelpersService } from '@core/services/helpers.service';
import { ModalService } from '@core/services/modal.service';
import { StringHelpersService } from '@core/services/string-helpers.service';
import { PendingApprovalModalComponent } from '../../modals/pending-approval-modal/pending-approval-modal.component';
import { CompanySearchData, NetworkService } from '../../network.service';
import { finalize, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { CompanyLogoComponent } from '../../../core/components/company-logo/company-logo.component';
import { ScrollableAreaComponent } from '../../../core/components/scrollable-area/scrollable-area.component';

const COMPANY_LOAD_LIMIT = 20;

@UntilDestroy()
@Component({
  selector: 'app-existing-companies-search',
  templateUrl: './existing-companies-search.component.html',
  styleUrls: ['./existing-companies-search.component.scss'],
  standalone: true,
  imports: [ScrollableAreaComponent, CompanyLogoComponent, FocusableDirective, RouterLink, TranslateModule],
})
export class ExistingCompaniesSearchComponent implements OnInit {
  private readonly helpers = inject(HelpersService);
  private readonly stringHelpers = inject(StringHelpersService);
  private readonly modalService = inject(ModalService);
  private readonly networkService = inject(NetworkService);
  @Input() searchData: CompanySearchData;
  @Input() isSelecting: boolean;
  companySelected = output<Company>();

  public foundCompanies: Company[] = [];
  public isLoading = false;

  private totalCompanies: number = null;

  public ngOnInit(): void {
    this.loadCompanies();
  }

  public loadCompanies(): void {
    if (this.isLoading) {
      return;
    }

    if (this.totalCompanies !== null && this.totalCompanies === this.foundCompanies.length) {
      return;
    }

    this.isLoading = true;
    this.networkService
      .searchCompanies(this.searchData, this.foundCompanies.length, COMPANY_LOAD_LIMIT)
      .pipe(
        untilDestroyed(this),
        map((res) => {
          this.foundCompanies = [...this.foundCompanies, ...res.data];
          this.totalCompanies = res.total;
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe();
  }

  public getCompanyDetailsString(company: CompanySearchModel | any): string {
    return this.stringHelpers.getCompanyDetailsString(company);
  }

  public getDescription(company: CompanySearchModel | any): string {
    return this.helpers.getCompanyDescription(company);
  }

  public selectCompany(company: any): void {
    this.companySelected.emit(company);
  }

  public showPendingApprovalModal(): void {
    this.modalService.open({
      content: PendingApprovalModalComponent,
      options: {
        size: 'sm',
      },
    });
  }
}
