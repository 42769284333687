<scrollable-area
  (scrollDown)="loadCompanies()"
  [scrollActivationPercent]="70"
>
  @for (company of foundCompanies; track company) {
    <div class="existing-companies-search_company">
      <company-logo
        class="existing-companies-search_company-logo"
        [company]="company"
      ></company-logo>
      <div class="existing-companies-search_company-info">
        <div>
          @if (!company.isApproved) {
            <div
              [appFocusable]
              (click)="showPendingApprovalModal()"
              class="existing-companies-search_company-name"
            >
              {{ company.name }}
            </div>
          }
          @if (company.isApproved) {
            <a
              [appFocusable]
              target="_blank"
              [routerLink]="['/network-v2/company/' + company.id]"
              class="existing-companies-search_company-name"
            >
              {{ company.name }}
            </a>
          }
        </div>
        <div class="existing-companies-search_company-description">
          {{ getDescription(company) }}
        </div>
        <div class="existing-companies-search_company-address">
          {{ getCompanyDetailsString(company) }}
        </div>
      </div>
      <div class="existing-companies-search_company-actions">
        @if (!isSelecting && company.isApproved) {
          <a
            [appFocusable]
            class="btn btn btn-primary submit-btn existing-companies-search_view-btn"
            target="_blank"
            [href]="'network-v2/company/' + company.id"
          >
            <span>{{ 'common.buttons.view' | translate }}</span>
          </a>
        }
        @if (isSelecting) {
          <button
            class="btn btn btn-primary submit-btn existing-companies-search_view-btn"
            (click)="selectCompany(company)"
          >
            {{ 'common.buttons.select' | translate }}
          </button>
        }
      </div>
    </div>
  }
</scrollable-area>
