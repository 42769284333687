<div class="modal-header">
  <button
    (click)="activeModal.dismiss()"
    [appFocusable]
    aria-label="Close"
    class="close-btn"
    type="button"
  >
    <img
      alt="{{ 'common.buttons.close' | translate }}"
      appAssetSrc="assets/svg/common/cross.svg"
    />
  </button>
</div>

<div class="modal-body">
  <div class="company-creation-modal_body">
    <!-- company creation form -->
    @if (currentStep === steps.companyCreation) {
      <div
        [style.max-height.px]="maxScrollAreaHeight"
        class="temporary-scrollable-area"
      >
        <div class="company-creation-modal_content">
          <form
            #ngForm="ngForm"
            (ngSubmit)="checkAddress()"
            [formGroup]="companyForm"
            autocomplete="off"
          >
            <div class="company-creation-modal_step-name">
              {{ 'companyCreation.step' | translate: { stepNumber: '1', totalSteps: '2' } }}
            </div>
            <h1>{{ 'companyCreation.didntSeeCompany' | translate }}</h1>
            <h1>{{ 'companyCreation.noProblem' | translate }}</h1>
            <div class="company-creation-modal_sub-title">
              {{ 'companySelection.tellAboutCompany' | translate }}
            </div>
            <div
              [ngClass]="{
                'has-error': ngForm.submitted && companyForm.get('name').invalid,
              }"
              class="form-group"
            >
              <label
                class="required"
                for="companyName"
              >
                {{ 'companySelection.controls.companyName.label' | translate }}
              </label>
              <input
                formControlName="name"
                id="companyName"
                placeholder="{{ 'companySelection.controls.companyName.placeholder' | translate }}"
                type="text"
              />
              <form-error-messages
                [control]="companyForm.get('name')"
                [showErrors]="ngForm.submitted"
              >
              </form-error-messages>
            </div>
            @if (!strict) {
              <div
                [ngClass]="{
                  'has-error': ngForm.submitted && companyForm.get('alias').invalid,
                }"
                class="form-group"
              >
                <label
                  class="required"
                  for="companyName"
                >
                  {{ 'companySelection.controls.companyAlias.label' | translate }}
                </label>
                <input
                  formControlName="alias"
                  id="companyAlias"
                  placeholder="{{ 'companySelection.controls.companyAlias.placeholder' | translate }}"
                  type="text"
                />
                <form-error-messages
                  [control]="companyForm.get('alias')"
                  [showErrors]="ngForm.submitted"
                >
                </form-error-messages>
              </div>
            }
            <div
              [ngClass]="{
                'has-error': ngForm.submitted && companyForm.get('address').invalid,
              }"
              class="form-group"
            >
              <address-input
                [required]="strict || forceAddressLookup"
                [showErrors]="ngForm.submitted"
                formControlName="address"
                id="address"
                type="text"
              >
              </address-input>
            </div>
            <div
              [ngClass]="{
                'has-error': ngForm.submitted && companyForm.get('website').invalid,
              }"
              class="form-group"
            >
              <label for="website">
                {{ 'companySelection.controls.website.label' | translate }}
              </label>
              <input
                formControlName="website"
                id="website"
                placeholder="{{ 'companySelection.controls.website.placeholder' | translate }}"
                type="text"
              />
              <form-error-messages
                [control]="companyForm.get('website')"
                [showErrors]="ngForm.submitted"
              >
              </form-error-messages>
            </div>
            <div class="company-creation-modal_form-buttons">
              <button
                [appBtnLoading]="isLoading"
                [disabled]="ngForm.submitted && companyForm.invalid"
                class="btn btn-primary submit-btn"
                type="submit"
              >
                {{ 'common.buttons.continue' | translate }}
              </button>
              <button
                (click)="activeModal.dismiss()"
                class="btn btn-link"
              >
                {{ 'companySelection.buttons.goBack' | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    }
    <!--  -->

    <!-- Choosing addresses matched by smarty streets -->
    @if (currentStep === steps.addressSelection) {
      <div
        [style.max-height.px]="maxScrollAreaHeight"
        class="temporary-scrollable-area"
      >
        <div class="company-creation-modal_content">
          <h1>{{ 'companyCreation.isAddressCorrect' | translate }}</h1>
          <div class="company-creation-modal_sub-title">
            {{ 'companyCreation.isAddressCorrectSubMsg' | translate }}
          </div>
          <div
            (click)="selectedAddress = enteredAddress"
            class="company-creation-modal_address-details"
          >
            <div>
              <app-radio [(ngModel)]="selectedAddress"></app-radio>
              <span class="radio-label">
                {{ 'companyCreation.enteredAddress' | translate }}
              </span>
            </div>
            <div>
              <div>
                {{ enteredAddress.streetAddressLine1 }}
              </div>
              <div>
                {{ enteredAddress.streetAddressLine2 }}
              </div>
              <div>
                <span>{{ enteredAddress.city }}</span
                >, <span>{{ enteredAddress.state }}</span
                >,
                <span>{{ enteredAddress.zipCode }}</span>
              </div>
            </div>
          </div>
          <div
            (click)="selectedAddress = matchedAddress"
            class="company-creation-modal_address-details"
          >
            <div>
              <app-radio [(ngModel)]="selectedAddress"></app-radio>
              <span class="radio-label">
                {{ 'companyCreation.suggestedAddress' | translate }}
              </span>
            </div>
            <div>
              <div [class.invalid]="enteredAddress.streetAddressLine1 !== matchedAddress.streetAddressLine1">
                {{ matchedAddress.streetAddressLine1 }}
              </div>
              <div [class.invalid]="enteredAddress.streetAddressLine2 !== matchedAddress.streetAddressLine2">
                {{ matchedAddress.streetAddressLine2 }}
              </div>
              <div>
                <span [class.invalid]="enteredAddress.city !== matchedAddress.city">{{ matchedAddress.city }}</span
                >,
                <span [class.invalid]="enteredAddress.state !== matchedAddress.state">{{ matchedAddress.state }}</span
                >,
                <span [class.invalid]="enteredAddress.zipCode !== matchedAddress.zipCode">{{
                  matchedAddress.zipCode
                }}</span>
              </div>
            </div>
          </div>
          <div class="company-creation-modal_form-buttons">
            <button
              (click)="finishAddressSelection()"
              [appBtnLoading]="isLoading"
              class="btn btn-primary submit-btn"
              type="submit"
            >
              {{ 'common.buttons.continue' | translate }}
            </button>
            <button
              (click)="currentStep = steps.companyCreation"
              class="btn btn-link"
            >
              {{ 'companySelection.buttons.goBack' | translate }}
            </button>
          </div>
        </div>
      </div>
    }
    <!--  -->

    <!-- Address Not Found -->
    @if (currentStep === steps.addressInvalid) {
      <div
        [style.max-height.px]="maxScrollAreaHeight"
        class="temporary-scrollable-area"
      >
        <div class="company-creation-modal_content">
          <h1>{{ 'companyCreation.addressNotFound' | translate }}</h1>
          <div class="company-creation-modal_sub-title">
            {{ 'companyCreation.addressNotFoundSubMsg' | translate }}
          </div>
          <div class="company-creation-modal_address-details">
            <div>
              <img appAssetSrc="assets/svg/common/radio-button-selected.svg" />
              <span>
                {{ 'companyCreation.enteredAddress' | translate }}
              </span>
            </div>
            <div>
              <div class="invalid">
                {{ enteredAddress.streetAddressLine1 }}
              </div>
              <div class="invalid">
                {{ enteredAddress.streetAddressLine2 }}
              </div>
              <div class="invalid">
                <span>{{ enteredAddress.city }}</span
                >, <span>{{ enteredAddress.state }}</span
                >,
                <span>{{ enteredAddress.zipCode }}</span>
              </div>
            </div>
          </div>
          <div class="company-creation-modal_form-buttons">
            <button
              (click)="currentStep = steps.companyCreation"
              [disabled]="isLoading"
              class="btn btn-primary submit-btn"
              type="submit"
            >
              {{ 'companySelection.buttons.goBack' | translate }}
            </button>
          </div>
        </div>
      </div>
    }
    <!--  -->

    <!-- Company details when found matching company -->
    @if (currentStep === steps.companiesFound) {
      <div
        [style.max-height.px]="maxScrollAreaHeight"
        class="temporary-scrollable-area"
      >
        <div class="company-creation-modal_content">
          <h1>{{ 'companyCreation.matchedCompaniesMsg' | translate }}</h1>
          <div class="company-creation-modal_sub-title">
            {{ 'companyCreation.matchedCompaniesSubMsg' | translate }}
          </div>
          <app-existing-companies-search
            (companySelected)="selectFoundCompany($event)"
            [isSelecting]="isSelecting"
            [searchData]="companySearchData"
          >
          </app-existing-companies-search>
          <div class="company-creation-modal_form-buttons">
            <button
              (click)="ignoreFoundCompanies()"
              class="btn btn-primary submit-btn w-fit-content"
            >
              {{ 'companyCreation.buttons.companyNotListed' | translate }}
            </button>
            <button
              (click)="currentStep = steps.companyCreation"
              class="btn btn-link"
            >
              {{ 'companySelection.buttons.goBack' | translate }}
            </button>
          </div>
        </div>
      </div>
    }
    <!--  -->

    <!-- contact creation form -->
    @if (currentStep === steps.contactCreation) {
      <div
        [style.max-height.px]="maxScrollAreaHeight"
        class="temporary-scrollable-area"
      >
        <div class="company-creation-modal_content">
          <form
            #ngForm="ngForm"
            (ngSubmit)="checkExistingContacts()"
            [formGroup]="contactForm"
            autocomplete="off"
          >
            <div class="company-creation-modal_step-name">
              {{ 'companyCreation.step' | translate: { stepNumber: '2', totalSteps: '2' } }}
            </div>
            <h1>{{ 'companyCreation.addContactMsg' | translate }}</h1>
            <div class="company-creation-modal_sub-title">
              {{ 'companyCreation.addContactSubMsg' | translate }}
            </div>
            <div
              [ngClass]="{
                'has-error': ngForm.submitted && contactForm.get('firstName').invalid,
              }"
              class="form-group"
            >
              <label
                [class.required]="strict"
                for="firstName"
              >
                {{ 'companyCreation.controls.firstName.label' | translate }}
              </label>
              <input
                formControlName="firstName"
                id="firstName"
                placeholder="{{ 'companyCreation.controls.firstName.placeholder' | translate }}"
                type="text"
              />
              <form-error-messages
                [control]="contactForm.get('firstName')"
                [showErrors]="ngForm.submitted"
              >
              </form-error-messages>
            </div>
            <div
              [ngClass]="{
                'has-error': ngForm.submitted && contactForm.get('lastName').invalid,
              }"
              class="form-group"
            >
              <label
                [class.required]="strict"
                for="lastName"
              >
                {{ 'companyCreation.controls.lastName.label' | translate }}
              </label>
              <input
                formControlName="lastName"
                id="lastName"
                placeholder="{{ 'companyCreation.controls.lastName.placeholder' | translate }}"
                type="text"
              />
              <form-error-messages
                [control]="contactForm.get('lastName')"
                [showErrors]="ngForm.submitted"
              >
              </form-error-messages>
            </div>
            <div
              [ngClass]="{
                'has-error': ngForm.submitted && contactForm.get('workEmail').invalid,
              }"
              class="form-group"
            >
              <label
                class="required"
                for="workEmail"
              >
                {{ 'companyCreation.controls.workEmail.label' | translate }}
              </label>
              <input
                formControlName="workEmail"
                id="workEmail"
                placeholder="{{ 'companyCreation.controls.workEmail.placeholder' | translate }}"
                type="email"
              />
              <form-error-messages
                [control]="contactForm.get('workEmail')"
                [customErrors]="{
                  duplicate_authorized_domain: 'companyCreation.errors.emailExists',
                }"
                [showErrors]="ngForm.submitted"
              >
              </form-error-messages>
            </div>
            <div class="company-creation-modal_form-buttons">
              <button
                [appBtnLoading]="isLoading"
                [disabled]="ngForm.submitted && contactForm.invalid"
                class="btn btn-primary submit-btn"
                type="submit"
              >
                {{ 'common.buttons.continue' | translate }}
              </button>
              <button
                (click)="currentStep = steps.companyCreation"
                class="btn btn-link"
              >
                {{ 'companySelection.buttons.goBack' | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    }
    <!--  -->

    <!-- Company details when found matching contact -->
    @if (currentStep === steps.contactFound) {
      <div
        [style.max-height.px]="maxScrollAreaHeight"
        class="temporary-scrollable-area"
      >
        <div class="company-creation-modal_content">
          <div class="comapany-selection_selected-company">
            <h1>{{ 'companyCreation.contactFoundMsg' | translate }}</h1>
            <div class="company-creation-modal_sub-title">
              {{ 'companyCreation.contactFoundSubMsg' | translate }}
            </div>
            <div class="company-creation-modal_company-details">
              <company-info [company]="foundContact"></company-info>
            </div>
            <div class="company-creation-modal_form-buttons">
              @if (foundContact.isApproved) {
                <a
                  (click)="setCompanyAndClose()"
                  class="btn btn-primary submit-btn"
                >
                  {{ 'companyCreation.buttons.' + (isSelecting ? 'selectThisCompany' : 'viewCompany') | translate }}
                </a>
              }
              @if (!foundContact.isApproved) {
                <a
                  (click)="showPendingApprovalModal()"
                  class="btn btn-primary submit-btn"
                >
                  {{ 'companyCreation.buttons.' + (isSelecting ? 'selectThisCompany' : 'viewCompany') | translate }}
                </a>
              }
              <button
                (click)="currentStep = steps.contactCreation"
                class="btn btn-link"
              >
                {{ 'companySelection.buttons.goBack' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</div>
